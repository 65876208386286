<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="version-content" class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="row">Version</th>
              <td id="version">{{ version }}</td>
            </tr>
            <tr>
              <th scope="row">Version date</th>
              <td id="version-date">2024-10-04 13:17:09 EDT</td>
            </tr>
            <tr>
              <th scope="row">Environment name</th>
              <td id="environment-name">{{ environmentName }}</td>
            </tr>
            <tr>
              <th scope="row">Build number</th>
              <td id="build-number">2</td>
            </tr>
            <tr>
              <th scope="row">Git branch</th>
              <td id="git-branch">release_10_7_2024_alphabravo</td>
            </tr>
            <tr>
              <th scope="row">Git commit hash</th>
              <td id="git-commit">7fe4b7fd2827b66465f05d0bf2d3964f21917951</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
