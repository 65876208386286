import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '@environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagePreferencesService {
  constructor(private _httpClient: HttpClient, private _oauthService: OAuthService) {}

  getCustomerPreferences(ecn: string): Observable<any> {
    const url =
      ENVIRONMENT.apiConfig.services.preferenceManagement.basePath +
      ENVIRONMENT?.apiConfig?.services.preferenceManagement.getCustomerPreferences(ecn);
    return this._httpClient.get(url, { headers: this._getHttpHeaders() });
  }

  postCustomerPreferences(ecn: string, requestBody: any): Observable<any> {
    const url =
      ENVIRONMENT.apiConfig.services.preferenceManagement.basePath +
      ENVIRONMENT?.apiConfig?.services.preferenceManagement.postCustomerPreferences(ecn);
    return this._httpClient.post(url, requestBody, { headers: this._getHttpHeaders() });
  }

  _getHttpHeaders(): HttpHeaders {
    /* eslint-disable @typescript-eslint/naming-convention */
    const headers = new HttpHeaders({
      client_id: ENVIRONMENT?.apiConfig?.clientId,
      'X-NW-Transaction-ID': '' + Date.now(),
      'X-NWD-ConsumerID': ENVIRONMENT?.apiConfig?.consumerId,
      Authorization: 'Bearer ' + this._oauthService.getAccessToken()
    });
    /* eslint-enable @typescript-eslint/naming-convention */
    return headers;
  }
}
