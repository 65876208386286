import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PREFERENCES_PATHS } from '@app/preferences/preferences-paths';
import { CommonFlow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class SetAnnuityPreferencesGuard {
  private readonly _className = 'SetAnnuityPreferencesGuard';

  constructor(private _oAuthService: OAuthService, private _navigationService: NavigationService) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    // Can turn off Annuity eDelivery experiment by making this method always return true.
    // That will route all traffic to the existing Set Preferences page
    const idClaims = this._oAuthService.getIdentityClaims();
    const agreements: any[] = idClaims?.agreements;
    if (this.hasOnlyBillingAndAnnuity(agreements)) {
      this._navigationService.navigate(`${CommonFlow.PREFERENCES}/${PREFERENCES_PATHS.setAnnuityPreferences}`);
    } else {
      return true;
    }
  }

  hasOnlyBillingAndAnnuity(agreements: any): boolean {
    const annuityProductTypes = ['Billing', 'Individual Annuity'];
    let hasOneOrMoreAnnuities = false;
    for (const agreement of agreements) {
      if (!annuityProductTypes.includes(agreement.product_type)) {
        return false;
      }
      if (agreement.product_type === 'Individual Annuity') {
        hasOneOrMoreAnnuities = true;
      }
    }
    return hasOneOrMoreAnnuities;
  }
}
