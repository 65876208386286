import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { ManagePreferencesService } from '@app/preferences/set-annuity-preferences/manage-preferences/manage-preferences.service';
import { REGISTRATION_PATHS } from '@app/registration/registration-paths';
import { AdaBaseComponent } from '@shared/base-component/ada-base.component';
import { ContentHeaderService } from '@shared/content-header/content-header.service';
import { Flow } from '@shared/navigation/flow.enum';
import { NavigationService } from '@shared/navigation/navigation.service';
import { SessionKey } from '@shared/storage/storage.model';
import { ELIGIBLE_EMAIL_PREFERENCES, ESDDA_CONSENT_PREFERENCE } from './set-annuity-preferences.model';

@Component({
  selector: 'app-set-annuity-preferences',
  templateUrl: './set-annuity-preferences.component.html',
  styleUrls: ['./set-annuity-preferences.component.scss']
})
export class SetAnnuityPreferencesComponent extends AdaBaseComponent implements OnInit, AfterViewInit {
  protected readonly className = 'SetAnnuityPreferencesComponent';
  shouldShowPreferenceCenterWidget = false;
  hasErrorOnApiCall = false;
  isEligibleUser = true;
  emailEdeliveryPreferences: any[] = [];
  esddaPreference: any[] = [];

  constructor(
    private _contentHeaderService: ContentHeaderService,
    private _managePreferencesService: ManagePreferencesService,
    private _navigationService: NavigationService,
    private _elementRef: ElementRef
  ) {
    super();
    window.addEventListener('message', (event: MessageEvent) => this.handleMessageEvent(event), false);
  }

  ngOnInit(): void {
    this._contentHeaderService.updateProgress({
      title: 'Sign up for online access',
      progressTitle: 'Set preferences',
      progressPercent: 90
    });
    this._getCustomerPreferences(this.sessionService.get(SessionKey.CUSTOMER)?.ecn);
  }

  ngAfterViewInit() {
    setTimeout(this.updateProgressBar, 1000);
    this._elementRef.nativeElement
      .querySelector('preference-center')
      ?.addEventListener('outputEvent', this.updateProgressBar);
  }

  updateProgressBar() {
    const progressBar = document
      .querySelector('bolt-progress-bar')
      ?.shadowRoot?.querySelector('.bolt-progress-bar-wc--fill') as HTMLElement;
    if (progressBar) {
      progressBar.style.width = '90%';
    } else {
      setTimeout(this.updateProgressBar, 500);
    }
  }

  handleMessageEvent(event: MessageEvent) {
    if (event?.data?.name === 'paperlessPreferenceOptionSubmit') {
      if (event.data?.success === true) {
        this.logger.info(this.className, 'User has submitted paperless preference election', {
          existingPaperlessPreference: event.data?.existingPaperlessPreference,
          newPaperlessPreference: event.data?.newPaperlessPreference
        });
        this.continue();
      } else {
        this._handleApiCallError('Error from preference center widget');
        this.spinnerService.hide();
      }
    } else if (event?.data === 'radioOptionsContinueSuccess') {
      this.continue();
    }
  }

  handleRadioButtonChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.shouldShowPreferenceCenterWidget = target.value === 'will-customize-document-preferences';
  }

  continue(): void {
    this.spinnerService.show();
    if (!this.shouldShowPreferenceCenterWidget && !this.hasErrorOnApiCall) {
      const postCustomerPreferencesBody = this._buildPostCustomerPreferencesRequestBody(this.emailEdeliveryPreferences);
      this._managePreferencesService
        .postCustomerPreferences(this.sessionService.get(SessionKey.CUSTOMER)?.ecn, postCustomerPreferencesBody)
        .subscribe(
          () => {
            this._navigationService.navigate(`${Flow.REGISTRATION}/${REGISTRATION_PATHS.registerSuccess}`);
          },
          error => {
            this._handleApiCallError(error);
            this.spinnerService.hide();
          }
        );
    } else {
      this._navigationService.navigate(`${Flow.REGISTRATION}/${REGISTRATION_PATHS.registerSuccess}`);
    }
  }

  _getCustomerPreferences(ecn: string): void {
    this._managePreferencesService.getCustomerPreferences(ecn).subscribe(
      response => {
        this.emailEdeliveryPreferences = this._parseGetPreferencesResponse(response);
        if (!this.emailEdeliveryPreferences.length) {
          this._handleIneligibleUser();
        } else {
          this._enrollInEsdda();
        }
      },
      error => {
        this._handleApiCallError(error);
        this._handleIneligibleUser();
      }
    );
  }

  _parseGetPreferencesResponse(response: any): any[] {
    const emailEdeliveryPreferences: any[] = [];
    response.preferences.forEach((agreement: any) => {
      if (ELIGIBLE_EMAIL_PREFERENCES.includes(agreement.name)) {
        emailEdeliveryPreferences.push(agreement);
      } else if (agreement.name === ESDDA_CONSENT_PREFERENCE) {
        this.esddaPreference.push(agreement);
      }
    });
    return emailEdeliveryPreferences;
  }

  _buildPostCustomerPreferencesRequestBody(preferencesToOptInto: any): any {
    preferencesToOptInto.forEach((agreement: any) => {
      if (agreement.election === 'No') {
        agreement.election = 'Yes';
      }
    });
    return { preferences: preferencesToOptInto };
  }

  _handleIneligibleUser(): void {
    this.isEligibleUser = false;
    this.shouldShowPreferenceCenterWidget = true;
  }

  _enrollInEsdda(): void {
    const esddaPreferenceBody = this._buildPostCustomerPreferencesRequestBody(this.esddaPreference);
    this._managePreferencesService
      .postCustomerPreferences(this.sessionService.get(SessionKey.CUSTOMER)?.ecn, esddaPreferenceBody)
      .subscribe(
        response => {
          this.logger.info(this.className, 'User successfully consented to ESDDA');
        },
        error => {
          this.logger.error(this.className, 'ESDDA API call error', error);
        }
      );
  }

  _handleApiCallError(error: any): void {
    this.hasErrorOnApiCall = true;
    document.getElementById('set-preferences-error').setAttribute('style', 'display : block');
    this.logger.error(this.className, 'API call error', error);
  }
}
