<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="set-preferences-content" class="container">
      <div id="set-preferences-header" class="mb-3">
        <div id="set-preferences-error" style="display: none;">
          <bolt-alert-error #pageLevelError _id="page-level-error">
            We're temporarily unable to update preferences. Try updating them later. You may continue to your account.
          </bolt-alert-error>
        </div>
        <h2 id="set-preferences-title" class="h5">
          <strong>
            Set your document delivery preferences
          </strong>
        </h2>
      </div>
      <div id="set-preferences-paperless">
        <h2 class="h5 mb-3">
          Hit continue at the bottom of the screen if you'd like to move forward with your preferences set to paperless.
        </h2>
        <p class="mb-4">
          We'll send you an email with a link to view your statements, documents and other important notices as soon as
          they're available online in your Document Center. Receiving documents electronically has many advantages,
          including:
        </p>
        <ul class="list-group inline-list-style mb-4">
          <li>Immediate access, 24/7</li>
          <li>Reduces waste</li>
          <li>Increases security and convenience</li>
          <li>Streamlines recordkeeping</li>
        </ul>
        <p>
          Note: Certain documents will still be mailed to you as regulations require.
        </p>
        <p>
          If you'd like to receive documents and statements via U.S. mail or receive document delivery notifications via
          text instead, you can choose to customize your preferences by electing customize below and hitting continue.
          Remember, you can change these preferences at any time.
        </p>
      </div>

      <fieldset *ngIf="isEligibleUser" class="form-group" (change)="handleRadioButtonChange($event)">
        <legend>Choose one option:</legend>
        <div class="form-check">
          <label class="form-check-label" for="will-receive-electronic-documents">
            <input
              type="radio"
              class="form-check-input add-radio-button-margin"
              name="customize-document-delivery-preferences"
              id="will-receive-electronic-documents"
              value="will-receive-electronic-documents"
              checked
            />Email me when available online (paperless)
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label" for="will-customize-document-preferences">
            <input
              type="radio"
              class="form-check-input add-radio-button-margin"
              name="customize-document-delivery-preferences"
              id="will-customize-document-preferences"
              value="will-customize-document-preferences"
            />I want to customize my document delivery preferences
          </label>
        </div>
      </fieldset>
      <div
        [ngClass]="{
          'display-preference-center': shouldShowPreferenceCenterWidget,
          'hide-preference-center': !shouldShowPreferenceCenterWidget
        }"
      >
        <ciam-preference-center-widget id="preference_center_widget"></ciam-preference-center-widget>
      </div>
    </section>
    <section id="set-preferences-buttons" class="button-container">
      <div class="container button-stack">
        <bolt-button-primary ciamClickLogger _type="button" _id="set-preferences-continue" (click)="continue()">
          Continue
        </bolt-button-primary>
      </div>
    </section>
  </div>
</div>
